// ASU Design System Image styles without Bootstrap variables to override.

.uds-img {
  opacity: 1;
  margin-bottom: $uds-size-spacing-0;
  max-width: 900px;
  img, &:is(img) {
    border: 1px solid $uds-color-base-gray-3;
    width: 100%;
  }

  &.borderless {
    img {
      border-color: transparent;
      border: 0px;
    }
    .uds-figure {
      .uds-figure-caption {
        border-color: transparent;
      }
    }
  }

  // Images with captions
  .uds-figure {
    width: 100%;
    img.img-original {
      width: initial;
    }
    .uds-figure-caption {
      border: 1px solid $uds-color-base-gray-3;
      border-top: none;
      opacity: 1;
      background: $uds-color-background-white 0% 0% no-repeat padding-box;
      padding: 2rem;
      font-size: $uds-size-font-tiny;
      h3, .h3 {
        color: $uds-color-base-gray-7;
        margin: 0 0 1rem 0;
      }
    }
    .uds-caption-text {
      display: block;
      max-width: 700px;
      color: $uds-color-base-gray-7;
    }
  }

  // Drop shadow for images with and without caption
  &.uds-img-drop-shadow {
    box-shadow: $uds-size-spacing-0 $uds-size-spacing-1 $uds-size-spacing-2
      rgba(25, 25, 25, 0.2);
    .uds-figure {
      margin-bottom: $uds-size-spacing-0;
    }
  }
}
